import * as React from "react";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkText } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkMoreInfoTrigger } from "@egds/react-core/more-info-trigger";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { PriceLockupProps, PriceLockupAlign } from "./typings";

const getPriceSubtext = (subtext: string | string[], isLeftPosition: boolean, isPadded?: boolean) => {
  if (subtext.length > 0 && Array.isArray(subtext)) {
    const subtextList = subtext.map((text, index) => <div key={`price-subtext-${index}`}>{text}</div>);

    return (
      <UitkLayoutFlexItem>
        <UitkSpacing padding={{ blockstart: isPadded ? "one" : "unset" }}>
          <div>{subtextList}</div>
        </UitkSpacing>
      </UitkLayoutFlexItem>
    );
  }

  return (
    <UitkLayoutFlexItem>
      <UitkSpacing padding={{ blockstart: isPadded ? "one" : "unset" }}>
        <UitkText size={100} align={isLeftPosition ? "left" : "right"}>
          {subtext}
        </UitkText>
      </UitkSpacing>
    </UitkLayoutFlexItem>
  );
};

export const PriceLockup: React.FC<PriceLockupProps> = (props) => {
  const {
    align,
    lockupPrice,
    strikeThroughPrice,
    priceSubtextFirst,
    priceSubtextStandard,
    a11yPrice,
    a11yStrikeThroughDialogTrigger,
    hasMoreInfoTrigger,
    isStrikeThroughFirst,
    onClickMoreInfoTrigger,
    disclaimerRef,
    dateRange,
    secondaryPrice,
    taxAndFeeClarify,
    buttonStyle,
    nightlyPrice,
    formattedAmount,
  } = props;

  const isStrikeThroughFirstWithoutTrigger = Boolean(isStrikeThroughFirst && strikeThroughPrice && !hasMoreInfoTrigger);
  const hasStrikeThroughWithoutTrigger = Boolean(!isStrikeThroughFirst && strikeThroughPrice && !hasMoreInfoTrigger);
  const hasStrikeThroughTrigger = Boolean(
    !isStrikeThroughFirst && hasMoreInfoTrigger && strikeThroughPrice && a11yStrikeThroughDialogTrigger
  );
  const isLeftPosition = align === PriceLockupAlign.LEFT;

  const priceSubTextStandardValue = dateRange
    ? getPriceSubtext(dateRange, isLeftPosition, true)
    : priceSubtextStandard && getPriceSubtext(priceSubtextStandard, isLeftPosition, true);

  const priceSubTextFirstValue = () => {
    if (secondaryPrice) {
      return getPriceSubtext(secondaryPrice, isLeftPosition, false);
    } else if (formattedAmount) {
      return null;
    }
    return priceSubtextFirst && getPriceSubtext(priceSubtextFirst, isLeftPosition, false);
  };

  const strikeThroughTriggerAndPrice = (
    <>
      <UitkLayoutFlexItem alignSelf="center">
        <UitkMoreInfoTrigger>
          <button
            style={buttonStyle}
            ref={disclaimerRef}
            onClick={onClickMoreInfoTrigger}
            type="button"
            aria-label={a11yStrikeThroughDialogTrigger}
          >
            <UitkLayoutFlex alignItems="center" justifyContent="end">
              <UitkLayoutFlexItem>
                <UitkIcon name="info_outline" size={UitkIconSize.SMALL} />
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <del data-testid="strike-through-price-trigger">
                  <UitkText size={300}>{strikeThroughPrice}</UitkText>
                </del>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </button>
        </UitkMoreInfoTrigger>
      </UitkLayoutFlexItem>
      {lockupPrice && (
        <UitkLayoutFlexItem>
          <Price price={lockupPrice} dataTestId="price-with-trigger" />
        </UitkLayoutFlexItem>
      )}
    </>
  );

  return (
    <div data-testid="price-summary">
      <UitkLayoutFlex
        justifyContent="center"
        direction="column"
        wrap="wrap"
        alignItems={isLeftPosition ? PriceLockupAlign.LEFT : PriceLockupAlign.RIGHT}
      >
        {isStrikeThroughFirstWithoutTrigger && (
          <UitkLayoutFlexItem>
            <del data-testid="strike-through-first">
              <UitkText size={300}>{strikeThroughPrice}</UitkText>
            </del>
          </UitkLayoutFlexItem>
        )}
        <UitkLayoutFlexItem>
          <div>
            <span className="is-visually-hidden">{a11yPrice}</span>
            {hasStrikeThroughTrigger && (
              <Viewport>
                <ViewSmall>
                  <UitkSpacing padding={{ blockstart: "one" }}>
                    <UitkLayoutFlex justifyContent="end" direction="column" wrap="wrap">
                      {strikeThroughTriggerAndPrice}
                    </UitkLayoutFlex>
                  </UitkSpacing>
                </ViewSmall>
                <ViewLarge>
                  <UitkSpacing padding={{ blockstart: "one" }}>
                    <UitkLayoutFlex justifyContent="end" space="one" wrap="wrap">
                      {strikeThroughTriggerAndPrice}
                    </UitkLayoutFlex>
                  </UitkSpacing>
                </ViewLarge>
              </Viewport>
            )}
            {hasStrikeThroughWithoutTrigger && (
              <del data-testid="strike-through-without-trigger">
                <UitkText align={isLeftPosition ? "left" : "right"}>{strikeThroughPrice}</UitkText>
              </del>
            )}
            {lockupPrice && !hasStrikeThroughTrigger && (
              <Price price={lockupPrice} dataTestId="price-without-trigger" />
            )}
          </div>
        </UitkLayoutFlexItem>
        {priceSubTextFirstValue()}
        {taxAndFeeClarify && getPriceSubtext(taxAndFeeClarify, isLeftPosition, true)}
        {nightlyPrice && getPriceSubtext(nightlyPrice, isLeftPosition, true)}
        {priceSubTextStandardValue}
      </UitkLayoutFlex>
    </div>
  );
};

const Price = ({ price, dataTestId }: { price: string; dataTestId: string }) => (
  <UitkText size={600} weight="bold" data-testid={dataTestId}>
    {price}
  </UitkText>
);
