import { useLocalization } from "@shared-ui/localization-context";
import * as React from "react";
import { UitkText, UitkTextProps } from "@egds/react-core/text";
import { UitkLink } from "@egds/react-core/link";

interface PriceLockupGetRatesProps {
  hotelLink: string;
  textAlignment?: UitkTextProps["align"];
}

export const PriceLockupGetRates = ({ hotelLink, textAlignment }: PriceLockupGetRatesProps) => {
  const { formatText } = useLocalization();

  return (
    <UitkText size={300} weight="regular" align={textAlignment}>
      <UitkLink className="getRatesText">
        <a href={hotelLink}>{formatText("hotels.price.getRates")}</a>
      </UitkLink>
    </UitkText>
  );
};

export default PriceLockupGetRates;
